<template>
  <div class="encapsulate-wrapper shadow" ref="wrapper">
    <strong class="text-red" v-if="!value">{{ textError }}</strong>
  </div>
</template>

<script>
export default {
  name: 'ShadowContent',
  props: {
    value: { type: String, default: null },
    heightByBody: { type: Boolean, default: false },
    textError: { type: String, default: 'No data to display content' }
  },
  methods: {
    shadowResize () {
      this.$refs.wrapper.style.height = document.body.offsetHeight + 'px'
    },
    addShadow () {
      this.$refs.wrapper.attachShadow({ mode: 'open' }).innerHTML = this.value
      this.heightByBody && this.shadowResize()
    }
  },
  mounted () {
    this.value && this.addShadow()
  }
}
</script>
