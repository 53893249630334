<template>
  <div>
    <portal to="page-top-title">Template #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'templates-edit', params: { id: id } }" icon="fa-edit" text v-if="template.metadata.editable.this">Edit</btn>
    </portal>

    <box-single :url="`/templates/${id}`" data-prop="template" resp-prop="data" loader @request-after="requestAfter">
      <template slot="content">
        <row>
          <column-info title="Name">{{ template.name }}</column-info>
          <column-info title="Category">
            <router-link :to="{ name: 'templates-categories-single', params: { id: template.category.id } }">{{ template.category.name }}</router-link>
          </column-info>
          <column-info title="Created At">{{ template.created_at }}</column-info>
          <column-info title="Updated At">{{ template.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="Subject Categories">
            <em class="text-info" v-if="!template.subjectCategories.length">None</em>
            <div class="td-rows comma" v-else>
              <div class="td-row" v-for="subjectCategory in template.subjectCategories" :key="`subjectCategory-${subjectCategory.id}`">
                <router-link :to="{ name: 'subjects-categories-single', params: { id: subjectCategory.id } }">{{ subjectCategory.name }}</router-link>
              </div>
            </div>
          </column-info>
          <column-info title="Type">{{ template.type_text }}</column-info>
          <column-info title="Active">
            <badge-active :value="template.active"/>
          </column-info>
          <column-info title="Has URL">
            <badge-active :value="template.has_code_url"/>
          </column-info>
        </row>
        <row>
          <column-info title="Messages">
            <stats-messages :stats="templateStats"/>
          </column-info>
          <column-info title="Clicks">
            <stats-clicks :stats="templateStats"/>
          </column-info>
          <column-info title="Revenues">
            <stats-revenues :stats="templateStats"/>
          </column-info>
        </row>
      </template>
    </box-single>

    <box title="Content" :open="loaded" :loading="!loaded">
      <template slot="content">
        <row>
          <column :md="12" :sm="12">
            <shadow-content v-if="template.layout" :value="template.content_compiled"/>
            <p v-else-if="template.content">{{ template.content }}</p>
            <p v-else-if="loaded && !template.content && !template.layout" class="mt10 text-center text-red">
              <strong>No Content Added Yet</strong>
            </p>
          </column>
        </row>
      </template>
    </box>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import Btn from '@/views/components/simple/Btn'
import BadgeActive from '@/views/components/simple/BadgeActive'
import template from '@/modules/strubs/template'
import StatsMessages from '@/views/components/stats/Messages'
import StatsClicks from '@/views/components/stats/Clicks'
import StatsRevenues from '@/views/components/stats/Revenues'
import ShadowContent from '@/views/components/simple/ShadowContent'

export default {
  name: 'TemplatesSingle',
  metaInfo () {
    return { title: 'Template #' + this.id }
  },
  components: {
    BoxSingle,
    Btn,
    BadgeActive,
    StatsRevenues,
    StatsClicks,
    StatsMessages,
    ShadowContent
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      template: template,
      templateStats: {},
      loaded: false
    }
  },
  methods: {
    requestAfter () {
      this.loaded = true
      this.loadStats()
    },
    loadStats () {
      this.$http
        .get('/templates/stats', { params: { ids: [this.id] } })
        .then((res) => {
          const { data: { data } } = res
          const id = Number(this.id)

          this.templateStats = data.find((stats) => stats.id === id)
        })
    }
  }
}
</script>
